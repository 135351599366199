<template>
    <div class="adminpage page">
        <header class="main-header sticky-header-top" >
            <b-container fluid="xl">
                <b-row>
                    <b-col cols="12">                 
                        <b-navbar toggleable="md">
                            <router-link class="logo" :to="{path: '/'}">Luminor</router-link>
                            <div class="main-header__line"></div>   
                            <button type="button" class="btn btn--black" @click="logout()"><span>Logout</span> </button>              
                        </b-navbar>
                    </b-col>
                </b-row>        
            </b-container>
        </header>
        <b-container fluid="xl">
            <b-row>
                <b-col style="text-align:center">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"><span class="gold-lined-text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">Admin</span> felület</h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8" offset-md="2">
                    
                      <b-tabs content-class="mt-3">
                        <b-tab title="Meghívó küldése" active>
                            <div class="adminpage__lead">
                                Kérjük, adja meg a felhasználó nevét és e-mail címet, ahová a meghívót szeretné küldeni.
                            </div>
                            <div class="adminpage__form-wrap animateScroll"  v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                                <div v-if="successSend" class="success-msg">
                                    <img src="@/assets/images/icons/ic-sparkles-black.svg" />
                                    A meghívót sikeresen elküldtük.
                                </div> 
                                <validation-observer v-slot="{ handleSubmit }" tag="div" ref="inviteForm" class="form-wrap">
                                    <form @submit.prevent="handleSubmit(inviteAccount)">
                                        <div>
                                            <ValidationProvider rules="required"  v-slot="{ failedRules }" vid="confirmation">
                                                <b-form-group   
                                                    id="login-name"
                                                    label="Név"
                                                    label-for="invitedName"  
                                                    class="animateScroll"    
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"
                                                >                                                        
                                                    <div class="form-input-wrap">
                                                        <b-form-input
                                                            class="animateScroll"
                                                            id="invitedName"
                                                            v-model="inviteForm.name"
                                                            type="text"
                                                            placeholder="Név..."
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                                                            ></b-form-input>
                                                    </div>
                                                    <div class="error">{{getErrorMessageAdmin("invitedName", Object.entries(failedRules)[0])}}</div>
                                                </b-form-group>
                                            </ValidationProvider>   
                                            <ValidationProvider rules="email|required" v-slot="{ failedRules }">
                                                <b-form-group   
                                                    id="login-email"
                                                    label="E-mail"
                                                    label-for="email"  
                                                    class="animateScroll"    
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"
                                                >                                                        
                                                    <div class="form-input-wrap">
                                                        <b-form-input
                                                            class="animateScroll"
                                                            id="email"
                                                            v-model="inviteForm.email"
                                                            type="text"
                                                            placeholder="E-mail..."
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                                                            ></b-form-input>
                                                    </div>
                                                    <div class="error">{{getErrorMessageAdmin("email", Object.entries(failedRules)[0])}}</div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <b-form-group   
                                                id="emaillang"
                                                label="Nyelv"
                                                label-for="emaillang"  
                                                class="animateScroll"    
                                                v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                                            >
                                                    <b-dropdown 
                                                    id="langSelect" 
                                                    :text="langChooserText" class="lang-selector"
                                                >
                                                    <b-dropdown-item v-for="lang in langOptions" :key="lang.code" @click="setInviteLang(lang.code)">
                                                        <span>{{lang.text}}</span>
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                                <div class="error" v-if="langError"> A nyelvet kötelező megadni</div>
                                            </b-form-group>
                                            
                                        </div>           
                                        <div class="error" style="margin-bottom: 30px">{{ error }}</div>
                                        <div>
                                            <button type="submit" class="btn btn--black btn--arrow"><span>Meghívó küldése</span></button>
                                        </div>
                                        </form>
                                </validation-observer>   
                                
                            </div>  
                        </b-tab>
                        <b-tab title="Meghívottak">
                            <!-- <b-table v-if="users.length>0" striped hover :items="users"></b-table> -->
                            <table class="user-table" v-if="users.length>0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>Név</th>
                                    <th>E-mail cím</th>
                                </tr>
                                <tr v-for="user in users" :key="user.id">
                                    <td>{{user.name}}</td>   
                                    <td>{{user.email}}</td>   
                                </tr>
                            </table>
                            <div v-else>Nincsenek meghívottak.</div>
                        </b-tab>   
                         <b-tab title="Bejelentkezések">
                            <!-- <b-table v-if="users.length>0" striped hover :items="users"></b-table> -->
                            <input class="form-control" placeholder="Keresett személy neve" v-model="log_filter" />
                            <table class="user-table" v-if="filtered_logs.length>0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>Név</th>
                                    <th>E-mail</th>
                                    <th>Időpont</th>
                                </tr>
                                <tr v-for="log in filtered_logs" :key="log.id">
                                    <td :class="{'admin': log.name == 'Admin'}">{{log.name}}</td>   
                                    <td :class="{'admin': log.name == 'Admin'}">{{log.email}}</td>   
                                    <td :class="{'admin': log.name == 'Admin'}">{{log.created_at}}</td>   
                                </tr>
                            </table>
                            <div v-else>Nincsenek logok.</div>
                        </b-tab>                                          
                    </b-tabs>                                  
                </b-col>
            </b-row>            
        </b-container>
    </div>
</template>


<script>
import apiHandlerMI from "@/mixins/apiHandlerMI"; 

import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import errorMessages from "@/assets/errorMessages.json";
import {setInteractionMode} from 'vee-validate';
import {required, email, confirmed} from "vee-validate/dist/rules";

setInteractionMode('eager');
extend('required', {
  ...required,
  message: "{_field_} is required."
});
extend("email", {
  ...email,
  message: "Type only valid email",
});
extend("confirmed", {
  ...confirmed,
  message: "Type the same password",
});

export default {
  data() {
    return {
      inviteForm: {
        email: null,
        name: null,   
        emaillang: null    
      },
      error: '',
      users:[],
      successSend: false,
      langError: false,
      logs: [],
      log_filter: "" 
    }
  },
  computed:{
        langOptions(){
            return this.$store.state.languages
        },
      langChooserText(){
          if (this.inviteForm.emaillang){            
            return this.CONFIG.languages.find(item => item.code === this.inviteForm.emaillang).text
          } else {
              return "Válasszon nyelvet"
          }
          
      },
       filtered_logs(){
          if (this.log_filter == "")
            return this.logs;
          var ret = [];
          for (var i = 0; i < this.logs.length; i++){
              if (this.logs[i].name.toLowerCase().indexOf(this.log_filter.toLowerCase()) >= 0){
                  ret.push(this.logs[i]);
              }
          }
          return ret;
      }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [apiHandlerMI],    
  methods: {
    getErrorMessageAdmin(fieldName, violation) {           
      return violation
          ? (errorMessages[fieldName] &&
          errorMessages[fieldName][violation[0]]['hu']) ??
          violation[1]
          : undefined;
    },   
    focusedParent(event){                
        if (event) {                
            let focusedElement = event.target
            focusedElement.classList.add('focused')        
            focusedElement.parentNode.classList.add('focused')                
        }            
    },
    focusedOutParent(event){
        if (event) {                
            let focusedElement = event.target
            focusedElement.classList.remove('focused')        
            focusedElement.parentNode.classList.remove('focused')                
        }
    },
    isAdmin(){
        let _this = this

        if (this.$store.state.user.user_type.toLowerCase() === this.CONFIG.adminType){
            //only the administrator has authority
            _this.getUsers();
             _this.getLogs();
        } else {
            this.$router.push('/')
        }
        
    },   
    getUsers(){
        var _this = this;

        _this.get('user',
        {params:{
            where: [['where','user_type','account']]
        }},function(resp){
            _this.users = resp.data.user;
            // _this.$forceUpdate();
        })
    },
    getLogs(){
        var _this = this;

        _this.get('login_log',
        {params:{
            order_by: [['id','desc']]
        }},function(resp){
            _this.logs = resp.data.login_log;
            // _this.$forceUpdate();
        })
    },
    inviteAccount(){
        var _this = this;    
            
        //lang is selected?
        if (!this.inviteForm.emaillang){            
            //write lang error
            _this.langError = true
        } else {
        
        let psw = _this.getPsw()   
        var langId = this.CONFIG.languages.find( item => item.code === _this.inviteForm.emaillang).id
        // console.log('langid', langId)     
        
        _this.post('user',
            {
                name: _this.inviteForm.name,
                email: _this.inviteForm.email,                
                password: psw,
                status: 'active',
                email_validated: 'valid'
            },
            {},
            function(resp){                
                var user = resp.data.result.user;                               

                _this.post('emailsender/2/' + langId,
                {
                    to: user.email,
                    data_replace:{
                        password: psw,
                        username: user.name,
                        base_Imgurl: _this.base_url
                    }
                },{},function(){          
                    _this.successSend = true
                    _this.resetInviteForm()          
                    _this.getUsers()                    
                })
            },function(err){
                // console.log(err)
                if (err.password){
                    _this.error = err.password
                } else {
                    _this.error ='Valami nem sikerült ellenőrizd az adatokat!'
                }                                
            })
        }
    },
    getPsw(){
        var length = 8;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
                
    },
    resetInviteForm(){
        
        this.inviteForm.email = ''
        this.inviteForm.name = ''
        this.$refs.inviteForm.reset()

        setTimeout(() => {
            this.successSend = false
        }, 6000);
    },
    logout(){        
        localStorage.removeItem(this.CONFIG.localStorageNameAdmin)
        this.$store.state.token = null
        this.$store.state.userData = null
        this.$router.push({name: 'admin'})
    },
    setInviteLang(lang){
        this.inviteForm.emaillang = lang
    }
  },
  created(){
      this.isAdmin()
  }
}

</script>
<style scoped>
.admin{
    opacity: 0.3;
}
</style>